<template>
  <div>
    <Header />
    <pageTitle txt="荷物番号CSV登録" :backBtn="backBtn" />

    <div id="wrapper">
      <div id="content">
        <div class="choose-blk">
          <p class="choose-txt">
            荷物番号のCSVファイルを選択してアップロードしてください。
          </p>
          <div class="btn-wrapper">
            <input type="file" id="file" @change="file_change" />
            <btnS
              class="choose"
              btnTxt="ファイルを選択"
              color="neutral"
              @click="file_selector"
            />
          </div>
          <p class="file-name">{{ fileName }}</p>
        </div>

        <btnL
          v-if="fileName"
          class="upload"
          btnTxt="アップロード"
          color="primary"
          @click="upload"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  @extend .f_body;
}

.choose-blk {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

.choose-txt {
  margin-bottom: 30px;
}

.btn-wrapper {
  margin-bottom: 30px;
}

input[type="file"] {
  display: none;
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL
  },
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      fileData: null,
      fileName: ""
    };
  },
  methods: {
    file_change(event) {
      const file = event.target.files;
      if (file.length > 0) {
        const reader = new FileReader();
        const obj = this;
        reader.onload = () => {
          obj.fileData = reader.result;
        };
        this.fileName = file[0].name;
        reader.readAsDataURL(file[0]);
      }
    },
    file_selector() {
      document.querySelector("#file").click();
    },
    async upload() {
      const api = new ApiClient();
      const param = {
        data: this.fileData
      };
      const result = await api.post("/iseya/output/read_csv", param);
      if (result.error == "") {
        window.alert("登録しました。");
      } else {
        window.alert(result.error);
      }
      this.fileData = "";
      this.fileName = "";
    }
  }
};
</script>
